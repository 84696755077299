import { Link } from '@remix-run/react'
import { cn } from '#app/utils/misc.tsx'
import { type MainNavItem } from '#types/index.js'
//import { useLockBody } from "@/hooks/use-lock-body"

interface MobileNavProps {
	items: MainNavItem[]
	children?: React.ReactNode
}

export function MobileNav({ items, children }: MobileNavProps) {
	//useLockBody()

	return (
		<div
			className={cn(
				'fixed inset-0 top-16 z-50 grid h-[calc(100vh-4rem)] grid-flow-row auto-rows-max overflow-auto p-6 pb-32 shadow-md animate-in slide-in-from-bottom-80 md:hidden',
			)}
		>
			<div className="relative z-20 grid gap-6 rounded-md bg-popover p-4 text-popover-foreground shadow-md">
				<Link
					to="/"
					aria-label="pcdh19"
					title="pcdh19"
					className="flex items-center space-x-2"
				>
					<img
						className="h-auto sm:w-10 md:w-12 lg:w-12"
						src="/img/brand/logo.png"
						alt="Logo Pdch19"
					/>
				</Link>
				<nav className="grid grid-flow-row auto-rows-max text-sm">
					{items.map((item, index) => (
						<Link
							key={index}
							to={item.disabled ? '#' : item.href}
							className={cn(
								'flex w-full items-center rounded-md p-2 text-sm font-medium hover:underline',
								item.disabled && 'cursor-not-allowed opacity-60',
							)}
						>
							{item.title}
						</Link>
					))}
				</nav>
				{children}
			</div>
		</div>
	)
}
