import { Link } from '@remix-run/react'
import { useState } from 'react'
import { siteConfig } from '#app/utils/config/site.ts'
import { cn } from '#app/utils/misc.tsx'
import { type MainNavItem } from '#types/index.js'
import { MobileNav } from '../mobile-nav.tsx'
import { Icon } from '../ui/icon.tsx'

interface MainNavProps {
	items?: MainNavItem[]
	children?: React.ReactNode
}

export default function MainNav({ items, children }: MainNavProps) {
	const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false)

	return (
		<div className="flex gap-6 md:gap-10">
			<Link
				to="/"
				className="hidden items-center space-x-2 md:flex"
				aria-label="PCDH19"
				title="PCDH19"
			>
				<img
					className="h-auto sm:w-10 md:w-12 lg:w-12"
					src="/img/brand/logo.png"
					alt="Logo PCDH19"
				/>{' '}
				<span className="hidden font-bold sm:inline-block">
					{siteConfig.name}
				</span>
			</Link>
			{items?.length ? (
				<nav className="hidden gap-6 md:flex">
					{items?.map((item, index) => (
						<Link
							key={index}
							to={item.disabled ? '#' : item.href}
							className={cn(
								'flex items-center text-lg font-medium transition-colors hover:text-foreground/80 sm:text-sm',
								item.disabled && 'cursor-not-allowed opacity-80',
							)}
						>
							{item.title}
						</Link>
					))}
				</nav>
			) : null}
			<button
				className="flex items-center space-x-2 md:hidden"
				onClick={() => setShowMobileMenu(!showMobileMenu)}
			>
				<img
					className="h-auto w-10 md:w-12 lg:w-12"
					src="/img/brand/logo.png"
					alt="Logo PCDH19"
				/>
				{showMobileMenu ? <Icon name="cross-1" /> : ''}
				<span className="float-left font-bold">Menu</span>
			</button>
			{showMobileMenu && items && (
				<MobileNav items={items}>{children}</MobileNav>
			)}
		</div>
	)
}
